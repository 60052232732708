const routes = [
  {
    folder: '',
    path: '/',
    // component: 'Dashboard',
    component: 'Customer',
    type: 0
  },
  {
    folder: '',
    path: '/customer',
    component: 'Customer',
    type: 0
  },
  {
    folder: 'Customer/',
    path: '/customer/details/:id',
    component: 'CustomerDetails',
    type: 0
  },
  {
    folder: '',
    path: '/customer_details',
    component: 'CustomerDetails',
    type: 0
  },
  {
    folder: '',
    path: '/reservations',
    component: 'Reservations',
    type: 0
  },
  {
    folder: '',
    path: '/rd_checkout',
    component: 'RDCheckout',
    type: 2
  },
  {
    folder: '',
    path: '/payments',
    component: 'PaymentManagement',
    type: 0
  },
  {
    folder: '',
    path: '/checkout',
    component: 'CheckoutPage',
    type: 0
  },
  {
    folder: '',
    path: '/gift_history',
    component: 'GiftHistory',
    type: 2
  },

  {
    folder: 'Reports/',
    path: '/daily_report',
    component: 'DailyReport',
    type: 0
  },
  {
    folder: 'Reports/',
    path: '/weekly_report',
    component: 'WeeklyReport',
    type: 0
  },
  {
    folder: 'Reports/',
    path: '/report1',
    component: 'FirstReportView',
    type: 0
  },
  {
    folder: 'Reports/',
    path: '/report2',
    component: 'SecondReportView',
    type: 0
  },
  {
    folder: 'Reports/',
    path: '/report3',
    component: 'ThirdReportView',
    type: 0
  },

  // Setting

  {
    folder: 'Settings/',
    path: '/system_info',
    component: 'SystemInfo',
    type: 1
  },
  {
    folder: 'Settings/',
    path: '/admin',
    component: 'Admin',
    type: 1
  },
  {
    folder: 'Settings/',
    path: '/product_list',
    component: 'ProductCategories',
    type: 0
  },
  {
    folder: 'Settings/',
    path: '/product_list_checkout',
    component: 'CheckoutProductLists',
    type: 0
  },
  {
    folder: 'Settings/',
    path: '/product_types',
    component: 'ProductTypes',
    type: 0
  },
  {
    folder: 'Settings/',
    path: '/gift_list',
    component: 'GiftList',
    type: 1
  },
  {
    folder: 'Settings/',
    path: '/company_list',
    component: 'CompanyList',
    type: 1
  },
  {
    folder: 'Settings/',
    path: '/payment_method',
    component: 'PaymentMethod',
    type: 1
  },

  {
    folder: 'auth/',
    path: '/user_edit',
    component: 'UserEdit',
    type: 0
  },
]
export const routesConfig = routes